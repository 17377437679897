
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


i("./-embroider-implicit-modules.js");

d("docs-app/app", function(){ return i("docs-app/app.ts");});
d("docs-app/config/environment", function(){ return i("docs-app/config/environment.js");});
d("docs-app/icons", function(){ return i("docs-app/icons.ts");});
d("docs-app/router", function(){ return i("docs-app/router.ts");});
d("docs-app/services/kolay/api-docs", function(){ return i("docs-app/services/kolay/api-docs.js");});
d("docs-app/services/kolay/compiler", function(){ return i("docs-app/services/kolay/compiler.js");});
d("docs-app/services/kolay/compiler/compile-state", function(){ return i("docs-app/services/kolay/compiler/compile-state.js");});
d("docs-app/services/kolay/compiler/import-map", function(){ return i("docs-app/services/kolay/compiler/import-map.js");});
d("docs-app/services/kolay/compiler/reactive", function(){ return i("docs-app/services/kolay/compiler/reactive.js");});
d("docs-app/services/kolay/docs", function(){ return i("docs-app/services/kolay/docs.js");});
d("docs-app/services/kolay/selected", function(){ return i("docs-app/services/kolay/selected.js");});
d("docs-app/services/kolay/types", function(){ return i("docs-app/services/kolay/types.js");});
d("docs-app/services/ember-primitives/setup", function(){ return i("docs-app/services/ember-primitives/setup.js");});
d("docs-app/services/page-title", function(){ return i("docs-app/services/page-title.js");});
d("docs-app/component-managers/glimmer", function(){ return i("docs-app/component-managers/glimmer.js");});
d("docs-app/initializers/app-version", function(){ return i("docs-app/initializers/app-version.js");});
d("docs-app/container-debug-adapter", function(){ return i("docs-app/container-debug-adapter.js");});
d("docs-app/services/-ensure-registered", function(){ return i("docs-app/services/-ensure-registered.js");});
d("docs-app/services/resize-observer", function(){ return i("docs-app/services/resize-observer.js");});
d("docs-app/services/body-class", function(){ return i("docs-app/services/body-class.js");});
d("docs-app/routes/api-docs", function(){ return i("docs-app/routes/api-docs.ts");});
d("docs-app/templates/application", function(){ return i("docs-app/templates/application.ts");});
d("docs-app/routes/application", function(){ return i("docs-app/routes/application.ts");});
d("docs-app/templates/index", function(){ return i("docs-app/templates/index.ts");});
d("docs-app/templates/page", function(){ return i("docs-app/templates/page.ts");});





if (!runningTests) {
  i("../app").default.create({"name":"docs-app","version":"0.0.0+90c5bf34"});
}

