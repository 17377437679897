import api from "!../../../../../node_modules/.pnpm/style-loader@2.0.0_webpack@5.93.0/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.93.0/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[5].use[1]!../../../../../node_modules/.pnpm/postcss-loader@8.1.1_postcss@8.4.41_typescript@5.5.4_webpack@5.93.0/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[7].use[0]!./layout.css";

var options = {"injectType":"styleTag"};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};